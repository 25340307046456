import services from "../axios/dbManag";

export const updateSocieta = (societa) => {
  return services.dbManag
    .post("/anagrafiche/societa/edit", {
      ...societa,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const updateEnte = (ente) => {
  return services.dbManag
    .post("/anagrafiche/enti-aggregati/edit", {
      ...ente,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
