import services from "../axios/dbManag";

export const viewAnagrafica = (id) => {
  return services.dbManag
    .post(`/anagrafiche/societa/view`, {
      id,
    })
    .then((res) => {
      const status = res.status;
      const { campi_Locked, comandi_Locked, record, results } = res.data;
      return { campi_Locked, comandi_Locked, record, results, status };
    })
    .catch((e) => {
      // erroreStatus.value = e.response.status;
      const { status } = e.response;
      return { status };
    });
};

export const viewAnagraficaEnte = (id) => {
  return services.dbManag
    .post(`/anagrafiche/enti-aggregati/view`, {
      id,
    })
    .then((res) => {
      const status = res.status;
      const { campi_Locked, comandi_Locked, record, results } = res.data;
      return { campi_Locked, comandi_Locked, record, results, status };
    })
    .catch((e) => {
      // erroreStatus.value = e.response.status;
      const { status } = e.response;
      return { status };
    });
};
